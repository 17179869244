/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/users/mohamed_hassan-5229782"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=8782"
  }, "자바 라이센스"), "\n", React.createElement(_components.a, {
    href: "https://www.oracle.com/kr/java/java-se-subscription/"
  }, "Oracle Java SE"), "\n", React.createElement(_components.a, {
    href: "https://docs.docker.com/subscription/desktop-license/"
  }, "도커 데스크탑 인당 라이센스"), "\n", React.createElement(_components.a, {
    href: "https://byline.network/2023/03/0317/"
  }, "바이라인 네트워크 기사"), "\n", React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/corretto/"
  }, "아마존 코레토"), "\n", React.createElement(_components.a, {
    href: "https://developers.redhat.com/products/openjdk/download"
  }, "레드햇 jdk")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "ChatGPT 플러그인\n", React.createElement(_components.a, {
    href: "https://bing.com"
  }, "빙"), "\n", React.createElement(_components.a, {
    href: "https://chat.openai.com/"
  }, "chatgpt4"), "\n", React.createElement(_components.a, {
    href: ""
  }, "Speak"), "\n", React.createElement(_components.a, {
    href: "https://www.digitaltoday.co.kr/news/articleView.html?idxno=469613"
  }, "전문직 시험 점수"), "\n", React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=8774"
  }, "copilot-x"), "\n", React.createElement(_components.a, {
    href: "https://news.microsoft.com/en-ca/2023/07/11/enhancing-everyday-life-with-ai/"
  }, "Microsoft - AI와 함께하는 일의 미래"), "\n", React.createElement(_components.a, {
    href: "https://workspace.google.com/solutions/ai/"
  }, "구글 웍스페이스 AI"), "\n", React.createElement(_components.a, {
    href: "https://www.microsoft.com/ko-kr/microsoft-loop?ms.url=microsoftcommicrosoft-loop"
  }, "Microsoft 루프"), "\nChatGPT 보안 이슈\nBard 공개 전"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "애플페이\n", React.createElement(_components.a, {
    href: "https://www.hyundaicard.com/cpu/ug/CPUUG4001_02.hc"
  }, "현대카드 체크카드"), "\n현대카드 컨텍리스\n현대카드 2위 vs. 삼성\n아멕스 현대 독점\n현대카드 실적\n애플페이 수수료\n네이버 페이\n라인 야후 합병\n네이버 페이 5년 검색"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.motorshow.or.kr/"
  }, "모빌리티 쇼"), "\n", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=eAjRCbJhfdc"
  }, "모카 ev9 컨셉카"), "\nev 트렌드쇼\n테슬라 모델x\n블로터 휴머노이드 로봇\nkg모빌리티 - 쌍용차"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
